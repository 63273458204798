.price {

  &__description {
    margin-top: 15px;
  }

  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: $black-color;
  }

  &__price {
    margin-top: 25px;

    font-family: $second-font;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: $dark-blue-color;
  }
}

@include respond-up('large') {
  .price {

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 20%);
      grid-row-gap: 36px;
      grid-column-gap: 60px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .price {
    &__list {
      display: grid;
      grid-template-columns: repeat(3, 27%);
      grid-row-gap: 36px;
      grid-column-gap: 60px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

@include respond-down('small') {
  .price {
    &__price {
      margin-top: 20px;
    }
    &__item {
      margin-top: 20px;
    }
  }
}