.contacts {
  overflow-x: hidden;

  &__title {
    text-align: center;
  }

  &__main {
    display: flex;
  }

  &__phone {
    margin-top: 10px;
  }

  &__address {
    display: block;
  }

  &__phones {
    display: flex;
    flex-direction: column;
  }

  &__schedule-element {
    display: grid;
    grid-template-columns: 110px 1fr;
    padding-top: 10px;
  }

  &__social {
    display: inline-block;
  }
}

@include respond-up('x-large') {
  .contacts {
    padding-top: 160px;
    padding-bottom: 140px;

    &__info {
      padding-bottom: 60px;
    }

    &__main {
      margin-top: 90px;
    }
  }
}

@include respond-up('large') {
  .contacts {
    &__address,
    &__schedule {
      margin-top: 40px;
    }

    &__social {
      margin-top: 45px;
    }

    &__map {
      height: 33vw;
      flex: 0 0 1200/1920*100%;
    }

    &__info {
      flex: 0 0 720/1920*100%;
      padding-left: 10vw;
      margin: auto 0;
    }
  }
}

@include respond('large') {
  .contacts {
    padding-top: 140px;
    padding-bottom: 105px;

    &__main {
      margin-top: 80px;
    }
  }
}

@include respond('medium') {
  .contacts {
    padding-top: 90px;
    padding-bottom: 90px;

    &__map {
      height: 33vw;
      flex: 0 0 calc(var(--grid-column7) + 29px);
    }

    &__info {
      flex: 0 0 calc(var(--grid-colum5) + var(--grid-gap));
      padding-left: calc(2 * var(--grid-gap) + var(--grid-column1));
      margin: auto 0;
    }

    &__address,
    &__schedule {
      margin-top: 30px;
    }

    &__social {
      margin-top: 65px;
    }

    &__main {
      margin-top: 35px;
    }

    &__map {
      height: clamp(389px, 50vw, 550px);
    }
  }
}

@include respond-down('medium') {
  .contacts {
  }
}

@include respond-down('small') {
  .contacts {
    padding-top: 60px;
    padding-bottom: 40px;

    &__main {
      flex-direction: column-reverse;
      padding-left: 23px;
      padding-right: 23px;
      margin-top: 30px;
    }

    &__address,
    &__schedule {
      margin-top: 25px;
    }

    &__social {
      margin-top: 35px;
    }

    &__map {
      margin-top: 40px;
      height: clamp(389px, 50vw, 550px);
    }
  }
}