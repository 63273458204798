.specialist {

  &__title {
    color: $black-color;
  }

  &__image {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }

  &__form {
    display: flex;
    justify-content: center;
  }
}

@include respond-up('large') {
  .specialist {
    padding-top: 70px;
    padding-bottom: 116px;

    &__info {
      display: flex;
      justify-content: center;
      margin-bottom: 100px;
    }

    &__description {
      max-width: 393px;
      margin-right: 53px;
    }

    &__image {
      width: 408px;
      height: 496px;
    }

    &__text {
      margin-top: 50px;
    }

  }
}

@include respond('medium') {
  .specialist {
    padding-top: 76px;
    padding-bottom: 73px;

    &__info {
      display: flex;
      justify-content: center;
      margin-bottom: 90px;
    }

    &__description {
      max-width: 344px;
      margin-right: 22px;
    }

    &__image {
      width: 344px;
      height: 418px;
    }

    &__text {
      margin-top: 22px;
    }

  }
}

@include respond-down('small') {
  .specialist {
    padding-top: 32px;
    padding-bottom: 20px;

    &__info {
      margin-bottom: 50px;
    }

    &__description {
      max-width: 331px;
    }

    &__text {
      margin-top: 20px;
    }

    &__image {
      height: 400px;
      width: 100%;
      margin-top: 30px;
    }
  }

}
