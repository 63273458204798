.service {
  &__main {
    width: 100%;
  }

  &__title {
    text-align: center;
    color: $black-color;
  }

  &__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 145%;
    letter-spacing: -0.02em;
    color: $black-color;

    background-color: $primary-color;
  }

  &__prices {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__price {
    &_center {
      text-align: center;
    }
  }

  &__form {
    margin: 0 auto;
  }
}

@include respond-up('large') {
  .service {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__form {
      margin-top: 80px;
    }

    &__title {
      margin-top: 77px;
    }

    &__main {
      margin-top: 60px;
      max-width: 1010px;
    }

    &__description {
      padding: 42px 98px 46px;
    }

    &__prices {
      padding: 50px 99px 60px;
    }

    &__price {
      flex: 0 0 45%;
      margin-top: 70px;

      &:first-child,
      &:nth-child(2) {
        margin-top: 0;
      }

      &_center,
      &_wide {
        flex: 0 0 100%;

        &:nth-child(2) {
          margin-top: 70px;
        }
      }
    }

    &__form {
      padding-bottom: 100px;
    }
  }
}

@include respond-up('medium') {
  .service {
    &__main {
      border: 4px solid $primary-color;
    }
  }
}

@include respond('medium') {
  .service {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__form {
      margin-top: 80px;
    }

    &__title {
      margin-top: 77px;
    }

    &__main {
      margin-top: 35px;
    }

    &__description {
      padding: 35px;
    }

    &__prices {
      padding: 35px;
    }

    &__price {
      flex: 0 0 45%;
      margin-top: 75px;

      &:first-child,
      &:nth-child(2) {
        margin-top: 0;
      }

      &_center,
      &_wide {
        flex: 0 0 100%;
      }
    }

    &__form {
      padding-bottom: 100px;
    }
  }
}

@include respond-down('small') {
  .service {
    &__title {
      margin-top: 32px;
    }

    &__main {
      border: 3px solid $primary-color;
      margin-top: 30px;
    }

    &__description {
      padding: 20px 16px;
    }

    &__prices {
      padding: 20px 16px;
    }

    &__price {
      flex: 0 0 100%;

      &:not(:first-child) {
        margin-top: 40px;
      }
    }

    &__form {
      margin-top: 50px;
    }
  }
}
