//не работает это здесь
//тут row column large-12

/**

Задаёт фиксированные отступы по краям контейнера

Example:

$grid: (
  small: (
    from: 0,
    to: 977,
    spacer: 40
  ),
  large: (
    from: 978,
    spacer: 20
  )
);

*/

$max-row-width: 1540;
$grid-columns: 12;

$small-breakpoint: 375;
$medium-breakpoint: 768;
$large-breakpoint: 1280;
$x-large-breakpoint: 1920;

$grid: (
  x-small: (
    from: 0,
    to: $small-breakpoint - 1,
    gutter: 22,
    spacer: 23px
  ),
  small: (
    from: $small-breakpoint,
    to: $medium-breakpoint - 1,
    gutter: 22,
    spacer: 23px
  ),
  medium: (
    from: $medium-breakpoint,
    to: $large-breakpoint - 1,
    gutter: 22,
    spacer: 29px
  ),
  large: (
    from: $large-breakpoint,
    to: $x-large-breakpoint - 1,
    gutter: 57,
    spacer: 0px
  ),
  x-large: (
    from: $x-large-breakpoint,
    gutter: 57,
    spacer: 0px
  )
);

/**
Этот параметр изменяемый, например на 'vw', если сайт тянется
По умолчанию - 'px'
 */
$spacer-unit: '';

.container {
  max-width: calc(var(--max-row-width));
  margin: 0 auto;
}

@each $name, $data in $grid {
  @if map-has_key($data, spacer) {
    @include respond($name) {
      .container {
        &_spacer {
          padding: 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }

    @include respond-up($name) {
      .container {
        &_spacer-#{$name}-up {
          padding: 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }

    @include respond-down($name) {
      .container {
        &_spacer-#{$name}-down {
          padding: 0 #{map-get($data, spacer)}#{$spacer-unit};
        }
      }
    }
  }
}