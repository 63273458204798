.recall-form {

  .request-form__data {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title,
  &__success-title {
    color: $black-color;
    text-align: center;
  }

  &__subtitle,
  &__success-subtitle {
    text-align: center;
  }

  &__data {
    display: flex;

    label {
      display: none;
    }
  }

  &__button-container {
    display: flex;
  }

  &__policy {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 145%;
    letter-spacing: -0.02em;
    color: $grey-main-text-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__link {
    color: $black-color;
  }

  .request-form__success {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__success-image {
    background: {
      size: contain;
      repeat: no-repeat;
      position: 50% 50%;
    };
  }
}

@include respond-up('large') {
  .recall-form {
    max-width: calc(50% + 100px);
    align-items: center;

    &__data-container {
      margin-top: 80px;
    }

    &__subtitle {
      margin-top: 24px;
    }

    &__data {
      margin: 0 -23px;

      .form-field {
        padding: 0 23px;
        flex: 0 0 50%;
        margin-bottom: 0;
      }
    }

    &__button-container {
      margin-top: 50px;
    }

    &__button {
      margin-right: 59px;
      padding: 0 40px;
    }

    &__success-image {
      width: 156px;
      height: 156px;
    }

    &__success-title {
      margin-top: 50px;
    }

    &__success-subtitle {
      margin-top: 24px;
    }
  }
}

@include respond('medium') {
  .recall-form {
    align-items: center;

    &__data-container {
      margin-top: 80px;
    }

    &__subtitle {
      margin-top: 22px;
    }

    &__data {
      margin: 0 -23px;

      .form-field {
        padding: 0 23px;
        flex: 0 0 50%;
        margin-bottom: 0;
      }
    }

    &__button-container {
      margin-top: 50px;
    }

    &__button {
      margin-right: 59px;
      padding: 0 40px;
    }

    &__success-image {
      width: 110px;
      height: 110px;
    }

    &__success-title {
      margin-top: 60px;
    }

    &__success-subtitle {
      margin-top: 22px;
    }
  }
}

@include respond-down('small') {
  .recall-form {

    &__subtitle {
      margin-top: 18px;
    }

    &__data {
      flex-direction: column;
      margin-top: 24px;

      .form-field {
        flex: 1 1 100%;
        margin: 0;
        &:nth-child(2) {
          margin-top: 28px;
        }

      }
    }

    &__button-container {
      flex-direction: column;
      align-items: center;
      margin-top: 35px;
    }

    &__button {
      padding: 0 38px;
    }

    &__policy {
      text-align: center;
      margin-top: 18px;
      display: block;
    }

    &__success-image {
      width: 108px;
      height: 108px;
    }

    &__success-title {
      margin-top: 30px;
    }

    &__success-subtitle {
      margin-top: 18px;
    }
  }
}