.social {
  cursor: pointer;

  &__link {
    padding-top: 10px;
    padding-bottom: 10px;

    &:not(:first-child) {
      margin-left: 6px;
    }
  }

  &__circle {
    fill: #fff;
  }

  &__circle,
  &__icon {
    transition: $default-transition;
  }

  &__icon-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 165%;
    letter-spacing: -0.02em;
    color: $grey-main-text-color;
    margin-left: 6px;
    position: relative;

    &:before {
      content: '';
      width: 0;
      height: 1px;
      position: absolute;
      bottom: -4px;
      right: 0;
      background-color: $black-color;
      transition: all .34s cubic-bezier(.72, .2, .15, 1);
    }
  }

  &__viber-icon,
  &__whatsApp-icon,
  &__telegram-icon {
    display: flex;
    align-items: center;
  }

  &__viber-icon,
  &__whatsApp-icon {
    svg {
      width: 14px;
      height: 14px;
    }
  }

  &__telegram-icon {
    svg {
      width: 16px;
      height: 14px;
    }
  }

  &__messengers {
    &:hover {
      .social {
        &__icon-text {
          &:before {
            width: 100%;
            left: 0;
            transition: all .55s cubic-bezier(.91, .4, .15, 1);
          }
        }
      }
    }
  }

  &__bottom {
    margin-top: 20px;
  }
}

@include respond-up('x-large') {
  .social {
    &__bottom {
      margin-top: 25px;
    }
  }
}

@include respond-up('large') {
  .social {
    &__link {
      &:hover {
        .social {
          &__circle {
            fill: $green-color;
          }

          &__icon {
            fill: #fff;
          }
        }
      }
    }

    &__messengers {
      &:not(:first-child) {
        margin-left: 16px;
      }
    }

    &__bottom {
      display: flex;
    }
  }
}

@include respond('medium') {
  .social {
    &__bottom {
      display: flex;
      flex-direction: column;
    }

    &__icon-text {
      &:before {
        bottom: 1px;
      }
    }

    &__messengers {
      &:not(:first-child) {

      }
    }
  }
}

@include respond-down('small') {
  .social {
    &__bottom {
      display: flex;
    }

    &__messengers {
      &:not(:first-child) {
        margin-left: 16px;
      }
    }

    &__link {
      svg {
        width: 38px;
        height: 38px;
      }
    }
  }
}