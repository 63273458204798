.testing {
  background-color: $primary-color;
  z-index: 150;

  &__items {
    justify-content: space-between;
  }

  &__title {
    color: $black-color;
  }

  &__wrap {
    max-width: 1920px;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  &__text {
    background-color: #fff;
    border: 6px solid #fff;

    &_gray {
      background-color: $t;
      color: $grey-main-text-color;
    }
  }

  &__name {
    white-space: nowrap;
    display: inline-block;
  }

  &__number {
    display: block;
  }

  &__item {
    display: block;

    &.active {
      .testing {
        &__name {
          color: $black-color;
        }
      }
    }
  }

  &__number {
    color: #fff;
    line-height: 100%;
    font-weight: 500;
    letter-spacing: -0.02em;
  }

  &__item {
    &.active {
      .testing {
        &__name {
          &::before {
            width: 100%;
            left: 0;
            transition: all .55s cubic-bezier(.91, .4, .15, 1);
          }
        }
      }
    }
  }

  &__link {
    &:hover {
      .testing {
        &__name {
          color: $black-color;
          transition: all .4s;
        }
      }
    }
  }

  &__name {
    color: $grey-main-text-color;
    position: relative;

    &::before {
      content: '';
      width: 0;
      height: 2px;
      position: absolute;
      bottom: -4px;
      right: 0;
      background-color: $black-color;
      transition: all .34s cubic-bezier(.72, .2, .15, 1);
    }
  }
}

@include respond-up('x-large') {
  .testing {
    &__items {
      padding-right: 144px;
    }

    &__main {
      flex: 0 0 38%;
    }

    &__content {
      align-self: flex-start;
      padding-top: 35px;
      flex: 0 0 48%;
    }

    &__wrap {
      padding: 165px 190px;
    }

    &__description {
      margin-top: 45px;
    }

    &__title {
      margin-bottom: 70px;
    }

    &__button {
      margin-top: 45px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
    }
  }
}

@include respond('large') {
  .testing {
    &__items {
      padding-right: 71px;
    }

    &__main {
      flex: 0 0 43%;
    }

    &__content {
      align-self: flex-start;
      flex: 0 0 48%;
    }

    &__wrap {
      padding: 168px 126px;
    }

    &__description {
      margin-top: 40px;
    }

    &__title {
      margin-bottom: 50px;
    }

    &__button {
      margin-top: 35px;
    }
  }
}

@include respond-up('large') {
  .testing {
    &__button {
      margin-top: 45px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
    }

    &__text {
      padding: 45px 55px;
      position: relative;
      font-weight: 500;
      font-size: 20px;
      line-height: 165%;
      letter-spacing: -0.02em;

      &_gray {
        font-weight: 400;
        font-size: 15px;
        line-height: 165%;
        letter-spacing: -0.02em;
      }
    }

    &__number {
      font-size: 100px;
    }

    &__wrap {
      justify-content: space-between;
    }

    &__content {
      position: relative;
    }

    &__items {
      margin: 0 -3%;
    }

    &__item {
      padding: 0 3%;
    }

    &__link {
      display: flex;
      flex-direction: column;
    }

    &__name {
      border-bottom: 2px solid $t;
      margin-top: 42px;
    }

    &__leaf-left {
      position: absolute;
      left: -11.5vw;
      bottom: -9vw;
      display: block;
      height: 26vw;
      width: 26vw;
    }

    &__leaf-right {
      position: absolute;
      right: -1vw;
      bottom: -6vw;
      display: block;
      height: 8vw;
      width: 10vw;

      &_mobile {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .testing {
    &__items {
      padding-right: 239px;
    }

    &__item {
      padding: 0 3%;

      &:first-child {
        padding-left: 0;
      }
    }

    &__number {
      font-size: 70px;
    }

    &__wrap {
      flex-direction: column;
      padding: 62px 30px 70px;
      overflow: hidden;
    }

    &__title {
      margin-bottom: 35px;
    }

    &__content {
      margin-top: 70px;
    }

    &__button {
      margin-top: 35px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
    }

    &__description {
      margin-top: 40px;
    }

    &__name {
      margin-top: 35px;
    }

    &__text {
      padding: 35px 50px;
      font-weight: 500;
      font-size: 20px;
      line-height: 165%;
      letter-spacing: -0.02em;

      &_gray {
        font-weight: 400;
        font-size: 15px;
      }
    }

    &__number-name-wrap {
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .testing {
    &__button {
      margin-top: 28px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
    }

    &__wrap {
      flex-direction: column;
      padding: 40px 22px 50px;
      overflow: hidden;
    }

    &__main {
      position: relative;
      max-width: 600px;
    }

    &__title {
      position: relative;
      margin-bottom: 25px;
    }

    &__text {
      padding: 20px;
      font-weight: 500;
      font-size: 16px;

      &_gray {
        font-weight: 400;
        font-size: 15px;
        line-height: 165%;
        letter-spacing: -0.02em;
      }
    }

    &__items {
      display: flex;
      margin-top: 50px;
    }

    &__number {
      display: none;
    }

    &__name {
      font-weight: 500;
      font-size: 15px;
      line-height: 135%;
      letter-spacing: -0.02em;

      &::before {
        height: 1px;
        bottom: -2px;
        transition: all .55s cubic-bezier(.91, .4, .15, 1);
      }
    }

    &__description {
      margin-top: 30px;
    }
  }
}