.laser {
  &__slider-wrapper {
    position: relative;
  }

  &__videos {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $black-color;
      opacity: .5;
    }
  }

  &__video {
    display: block;
  }

  &__title {
    color: #FFFFFF;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__description {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@include respond-up('x-large') {
  .laser {
    &__arrow-left,
    &__arrow-right {
      bottom: 70px;
    }
  }
}

@include respond-up('large') {
  .laser {
    &__arrow-left {
      position: absolute;
      left: calc(50% - 68px - 28px);
      transform: translateY(-50%);
      opacity: 0;
      pointer-events: none;
      transition: all .5s ease-in-out;
    }

    &__arrow-right {
      position: absolute;
      right: calc(50% - 68px - 28px);
      transform-origin: 50% 50%;
      transform: translateY(-50%) rotate(180deg);
      opacity: 0;
      pointer-events: none;
      transition: all .5s ease-in-out;
    }

    &__slider-wrapper {
      height: calc(100vw * 917 / 1920);

      &:hover {
        .laser__arrow-left {
          opacity: 1;
          pointer-events: auto;
          transform: translate3d(14px, -50%, 0);
        }

        .laser__arrow-right {
          opacity: 1;
          pointer-events: auto;
          transform: translate3d(-14px, -50%, 0) rotate(180deg);
        }
      }

      .slick-slide {
        height: calc(100vw * 917 / 1920);

        .laser {
          &__main,
          &__videos {
            height: calc(100vw * 917 / 1920);
          }
        }
      }
    }

    &__button {
      margin-top: 55px;
    }

    &__main {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__title {
      max-width: 1008px;

      &_mobile {
        display: none;
      }
    }

    &__description {
      color: #FFFFFF;
      max-width: 1008px;
      margin-top: 32px;
      text-align: center;
    }
  }
}

@include respond('large') {
  .laser {
    &__arrow-left,
    &__arrow-right {
      bottom: 10px;
    }
  }
}

@include respond-up('medium') {
  .laser {

    &__video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

@include respond('medium') {
  .laser {
    &__slider-wrapper {
      height: calc(100vw * 650 / 768);
      max-height: 70vh;

      .slick-slide {
        height: calc(100vw * 650 / 768);
        max-height: 70vh;

        .laser {
          &__main,
          &__videos {
            max-height: 70vh;
            height: calc(100vw * 650 / 768);
          }
        }
      }
    }

    &__button {
      margin-top: 40px;
    }

    &__main {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__title {
      max-width: 710px;

      &_mobile {
        display: none;
      }
    }

    &__description {
      color: #FFFFFF;
      max-width: 710px;
      margin-top: 18px;
      text-align: center;
    }

    &__arrow-left,
    &__arrow-right {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .laser {
    &__arrow-left,
    &__arrow-right {
      display: none;
    }
  }
}

@include respond-down('small') {
  .laser {
    &__title-wrap {
      &_mobile {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        padding: 0 23px;
      }
    }

    &__video {
      height: 56vw;
      width: 100%;
      object-fit: cover;

      &::-webkit-media-controls-play-button {
        display: none !important;
        -webkit-appearance: none;
      }

      &::-webkit-media-controls-panel {
        display: none !important;
        -webkit-appearance: none;
      }

      &::-webkit-media-controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none;
      }
    }

    &__wrap {
      position: relative;
    }

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 330px;

      &_desktop {
        display: none;
      }
    }

    &__description {
      padding: 0 23px;
      margin-top: 50px;
    }

    &__button {
      margin: 28px 22px 0;
    }
  }
}