.link {
  position: relative;
  transition: border-bottom-color .4s ease-in-out;
  border-bottom: 1px solid $t;

  &_2 {
    border-bottom: 2px solid $t;
  }
}

@include respond-up('large') {
  .link {
    &:hover {
      border-bottom: 1px solid $black-color;
    }

    &_2 {
      &:hover {
        border-bottom: 2px solid $black-color;
      }
    }
  }
}