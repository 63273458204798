$max-row-width: 1540;
$grid-columns: 12;

$small-breakpoint: 375;
$medium-breakpoint: 768;
$large-breakpoint: 1280;
$x-large-breakpoint: 1920;

//gutter это от ряда до края экрана

$grid: (
  x-small: (
    from: 0,
    to: $small-breakpoint - 1,
    gutter: 0,
    spacer: 23px
  ),
  small: (
    from: $small-breakpoint,
    to: $medium-breakpoint - 1,
    gutter: 0,
    spacer: 23px
  ),
  medium: (
    from: $medium-breakpoint,
    to: $large-breakpoint - 1,
    gutter: 0,
    spacer: 29px
  ),
  large: (
    from: $large-breakpoint,
    to: $x-large-breakpoint - 1,
    gutter: 0,
    spacer: 0px
  ),
  x-large: (
    from: $x-large-breakpoint,
    gutter: 0,
    spacer: 0px
  )
);

$body-font-size: 16px;

$font: 'Montserrat', "Arial", sans-serif;
$h-font: 'Montserrat', "Arial", sans-serif;
$second-font: 'Montserrat', "Arial", sans-serif;

$t: transparent;

$black-color: #2D2C2C;

$beige-color: #E0D6BF; //Beige

$headings-color: #E8E1D0;
$primary-color: #E8E1D0; //это фон
$pink-color: #7E434D; //кнопки
$button-bg-hover: #9A3C4C;
$green-color: #59705C;
$gray-color: rgba(0, 0, 0, 0.41);
$grey-text-color: #6B6860;
$grey-main-text-color: #605C55;
$pink-numbers-color: #EBC9BC;
$dark-blue-color: #121b2a;
$alert-color: #b50000;
$light-gray-color: #d8d8d8;

$default-transition: all 0.4s;
$default-timing-function: cubic-bezier(.25, .1, .25, 1);