.specialists {
  position: relative;

  &__item-specialty {
    display: block;
  }

  &__image {
    position: absolute;
    top: 50px;
    left: 8%;
    height: 16vw;
    width: 22vw;
  }

  &__item-image {
    display: block;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__item-link {
    width: 100%;
    height: 100%;
    display: block;
  }

  &__item-name {
    display: inline;
    position: relative;
    color: $black-color;
  }
}

@include respond-up('x-large') {
  .specialists {
    padding-top: 160px;

    &__wrapper {
      & .slick-slide {
        width: 368px !important;
        margin-right: 120px;

        &:nth-child(even){
          .specialists__item-content {
            order: 1;
            margin-top: 0;
          }
          .specialists__item-image {
            order: 2;
            margin-top: 45px;
          }
        }
      }

      & .slick-track {
        padding: 0 190px;
      }
    }

    & .arrow-left {
      margin-right: 20px;
    }

    &__title {
      margin-bottom: 80px;
    }

    &__image {
      top: 300px;
    }

    &__arrows {
      text-align: right;
      margin-bottom: 73px;
    }

    &__item {
      //width: 368px !important;
      display: flex !important;
      flex-direction: column;
    }

    &__item-image {
      padding-bottom: 97%;
    }

    &__item-content {
      margin-top: 45px;
    }

    &__item-specialty {
      margin-top: 12px;
    }
  }
}

@include respond('large') {
  .specialists {
    padding-top: 160px;

    &__wrapper {
      & .slick-slide {
        //width: 297px !important;
        margin-right: 115px;

        &:nth-child(even){
          .specialists__item-content {
            order: 1;
            margin-top: 0;
          }
          .specialists__item-image {
            order: 2;
            margin-top: 35px;
          }
        }
      }

      & .slick-track {
        padding: 0 125px;
      }
    }

    & .arrow-left {
      margin-right: 0;
    }

    &__title {
      margin-bottom: 80px;
    }

    &__image {
      top: 300px;
    }

    &__arrows {
      text-align: right;
      margin-bottom: 44px;
    }

    &__item {
      //width: 297px !important;
      display: flex !important;
      flex-direction: column;
    }

    &__item-image {
      padding-bottom: 97%;
    }

    &__item-content {
      margin-top: 35px;
    }

    &__item-specialty {
      margin-top: 12px;
    }
  }
}

@include respond('medium') {
  .specialists {
    padding-top: 70px;

    &__wrapper {
      & .slick-slide {
        //width: 284px !important;
        margin-right: 61px;

        &:nth-child(even){
          .specialists__item-content {
            order: 1;
            margin-top: 0;
          }
          .specialists__item-image {
            order: 2;
            margin-top: 25px;
          }
        }
      }

      & .slick-track {
        padding: 0 28px;
      }
    }

    & .arrow-left {
      margin-right: 0;
    }

    &__title {
      margin-bottom: 35px;
    }

    &__image {
      top: 300px;
    }

    &__arrows {
      text-align: right;
      margin-bottom: 30px;
    }

    &__item {
      //width: 284px !important;
      display: flex !important;
      flex-direction: column;
    }

    &__item-image {
      padding-bottom: 97%;
    }

    &__item-content {
      margin-top: 25px;
    }

    &__item-specialty {
      margin-top: 12px;
    }
  }
}

@include respond-down('small') {
  .specialists {
    padding-top: 40px;

    &__wrapper {
      & .slick-slide {
        //width: 212px !important;
        margin-right: 30px;

        &:nth-child(even){
          .specialists__item-content {
            order: 1;
            margin-top: 0;
          }
          .specialists__item-image {
            order: 2;
            margin-top: 18px;
          }
        }
      }

      & .slick-track {
        padding: 0 23px;
      }
    }

    & .arrow-left {
      margin-right: 0;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__image {
      top: 300px;
    }

    &__arrows {
      text-align: right;
      margin-bottom: 25px;
    }

    &__item {
      //width: 212px !important;
      display: flex !important;
      flex-direction: column;
    }

    &__item-image {
      padding-bottom: 97%;
    }

    &__item-content {
      margin-top: 18px;
    }

    &__item-specialty {
      margin-top: 10px;
    }
  }
}