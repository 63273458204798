.services {
  overflow: hidden;

  &__service {
    color: $gray-color;
  }

  &__left {
    display: flex;
  }

  &__category-name {
    position: relative;
    color: $black-color;
  }

  &__sections {
    display: flex;
    z-index: 50;
  }

  &__tab-link {
    text-align: right;
  }

  //&__tab {
  //  display: inline;
  //  text-align: end;
  //  border-bottom: 2px solid $t;
  //  transition: border-bottom-color .4s ease-in-out;
  //}
  //
  //&__tab-link,
  //&__section {
  //  width: 100%;
  //  display: flex !important;
  //  justify-content: flex-end;
  //}
  //
  &__section {
    &.active {
      .services__tab {
        border-bottom: 2px solid $black-color;
        //&:before {
        //  width: 100%;
        //  left: 0;
        //  transition: all .55s cubic-bezier(.91, .4, .15, 1);
        //}
      }
    }
  }

  &__categories {
    display: flex;
    flex-direction: column;
  }

  &__image,
  &__images {
    height: 100%;
  }

  &__image {
    position: relative;

    picture {
      display: flex;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@include respond-up('x-large') {
  .services {
    &__right {
      width: calc(50vw - 1540px / 2 + var(--grid-column2) + var(--grid-gap));
    }

    &__sections {
      margin-top: 100px;
    }

    &__contents {
      margin-top: 85px;
    }
  }
}

@include respond-up('large') {
  .services {

    &__categories {
      padding-bottom: 73px;
    }

    &__left {
      grid-column: 1/3;
      margin-bottom: 36px;
      padding-right: calc(var(--grid-column1) - 22px);
    }

    &__contents {
      grid-column: 3/5;
    }

    &__sections {
      height: fit-content;
      position: sticky;
      top: 30px;
      padding-bottom: 100px;
    }

    &__main {
      position: relative;
    }

    &__contents {
      padding-left: 100px;
      margin-left: -100px;
      padding-top: 15px;
    }

    &__contents,
    &__sections {
      max-height: calc(100vh - 85px);

      overflow: scroll visible;
      //-ms-overflow-style: none; /* IE and Edge */
      //scrollbar-width: none; /* Firefox */
      //
      //&::-webkit-scrollbar {
      //  display: none;
      //}
    }

    &__right {
      height: calc(100vh);
      width: calc(50vw - 1250px / 2 + var(--grid-column2) + var(--grid-gap));
      margin-left: calc(0px - var(--grid-gap));
    }

    &__services {
      padding-top: 23px;
    }

    &__service {
      &:not(:first-child) {
        padding-top: 12px;
      }
    }

    &__title {
      padding-top: 120px;
      padding-bottom: 90px;
    }

    &__sections {
      flex-direction: column;
      align-items: flex-end;
    }

    &__section {
      &:not(:first-child) {
        margin-top: 32px;
      }
    }
  }
}

@include respond('large') {
  .services {
    &__sections {
      margin-top: 50px;
    }

    &__contents {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .services {
    &__category {
      padding-right: 20px;

      &:not(:first-child) {
        padding-top: 73px;
      }
    }

    &__category-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-52%);
      left: calc(-44px - 35px);
    }
  }
}

@include respond('medium') {
  .services {
    padding-bottom: 70px;

    &__right {
      width: calc(var(--grid-column5) + var(--grid-spacer));
      height: 490px;
    }

    &__contents,
    &__right {
      margin-top: 60px;
    }

    &__services {
      margin-top: 23px;
    }

    &__contents {
      grid-column: 1/8;
      padding-left: calc(var(--grid-column1) * 1.5 + var(--grid-gap));
    }

    &__title {
      padding-top: 60px;
      padding-bottom: 40px;
    }

    &__left {
      grid-column: 1/13;
    }

    &__section {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
}

@include respond-down('medium') {
  .services {
    &__main {
      overflow: hidden;
    }

    &__service {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    &__sections {
      padding-left: var(--grid-spacer);
      padding-right: var(--grid-spacer);
    }

    &__section {
      white-space: nowrap;
    }

    &__left {
      margin-left: calc(0px - var(--grid-spacer));
      margin-right: calc(0px - 2 * var(--grid-spacer));
      padding-bottom: 2px;

      overflow: scroll visible;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .services {
    padding-bottom: 40px;

    &__services {
      margin-top: 18px;
    }

    &__category {
      &:not(:first-child) {
        margin-top: 49px;
      }
    }

    &__category-name {
      img {
        display: block;
        margin-bottom: 18px;
      }
    }

    &__contents {
      grid-column: 1/5;
      margin-top: 20px;
    }

    &__right {
      display: none;
    }

    &__left {
      grid-column: 1/5;
    }

    &__title {
      padding-top: 40px;
      padding-bottom: 25px;
    }

    &__section {
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
}