.slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 20px 0;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 56vw;

  list-style-type: none;

  li {
    margin: 0 4.5px;
  }

  button {
    display: block;
    width: 8px;
    height: 8px;
    padding: 0;

    border: none;
    border-radius: 100%;
    background-color: $primary-color;

    text-indent: -9999px;
  }

  li.slick-active button {
    background-color: $pink-color;
  }
}
