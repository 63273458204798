.recall-block {
  position: relative;
  background-color: $primary-color;
  overflow: hidden;

  &__circles-wrapper {
    position: absolute;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;

    form {
      $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

      #{$inputs}, textarea, select {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 50px $primary-color inset !important;
        }
      }
    }
  }

  &__form {
    .recall-form {
      &__title {
        line-height: 135%;
      }
    }
  }
}

@include respond-up('x-large') {
  .recall-block {
    &__circles-wrapper {
      &_first {
        .recall-block {
          &__circles {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-42%, -42%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 30px solid $beige-color;
            }

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-44%, -45%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 30px solid $beige-color;
            }
          }
        }
      }

      &_second {
        .recall-block {
          &__circles {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-56%, -49%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 30px solid $beige-color;
            }

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-55%, -49%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 30px solid $beige-color;
            }
          }
        }
      }
    }

    &__form {
      margin: 207px 0 238px;

      .recall-form {
        &__title {
          font-size: 45px;
        }
      }
    }
  }
}

@include respond-up('large') {
  .recall-block {
    &__circles {
      &:before {
        height: 464px;
        width: 464px;
      }

      &:after {
        height: 640px;
        width: 640px;
      }
    }

    &__form {
      .recall-form {
        &__title {
          max-width: 644px;
        }

        &__data-container {
          margin-top: 82px;
        }

        &__subtitle {
          margin-top: 40px;
          max-width: 588px;
        }

        &__data {
          margin: 0 -27px;

          .form-field {
            padding: 0 27px;
          }
        }
      }
    }

    &__circles-wrapper {
      &_first {
        bottom: 350px;
        left: -70px;
      }

      &_second {
        right: -110px;
        top: 40px;
      }
    }
  }
}

@include respond('large') {
  .recall-block {
    &__circles-wrapper {
      &_first {
        .recall-block {
          &__circles {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-42%, -21%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 30px solid $beige-color;
            }

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-44%, -28%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 30px solid $beige-color;
            }
          }
        }
      }

      &_second {
        .recall-block {
          &__circles {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-46%, -44%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 30px solid $beige-color;
            }

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-47%, -46%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 30px solid $beige-color;
            }
          }
        }
      }
    }

    &__form {
      margin: 188px 0 189px;

      .recall-form {
        &__title {
          font-size: 38px;
        }
      }
    }
  }
}

@include respond('medium') {
  .recall-block {
    &__circles {
      &:before {
        height: 284px;
        width: 333px;
      }

      &:after {
        height: 393px;
        width: 459px;
      }
    }

    &__circles-wrapper {
      &_first {
        .recall-block {
          &__circles {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-70%, 40%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 21px solid $beige-color;
            }

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-65%, 17%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 21px solid $beige-color;
            }
          }
        }
      }

      &_second {
        .recall-block {
          &__circles {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-58%, -55%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 21px solid $beige-color;
            }

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-56%, -53%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 21px solid $beige-color;
            }
          }
        }
      }
    }

    &__form {
      margin: 162px 0 165px;

      .recall-form {
        &__title {
          max-width: 495px;
          font-size: 32px;
        }

        &__data-container {
          margin-top: 60px;
        }

        &__subtitle {
          margin-top: 28px;
          max-width: 588px;
        }

        &__data {
          margin: 0 -27px;

          .form-field {
            padding: 0 27px;
          }
        }
      }
    }

    &__circles-wrapper {
      &_first {
        bottom: 350px;
        left: -70px;
      }

      &_second {
        right: -110px;
        top: 40px;
      }
    }
  }
}

@include respond-down('small') {
  .recall-block {

    &__circles-wrapper {
      &_first {
        .recall-block {
          &__circles {
            position: relative;

            &:before {
              height: 143px;
              width: 143px;
            }

            &:after {
              height: 198px;
              width: 198px;
            }

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(71%, 48%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 9px solid $beige-color;
            }

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(40%, 23%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 9px solid $beige-color;
            }
          }
        }
      }

      &_second {
        .recall-block {
          &__circles {
            position: relative;

            &:before {
              height: 112px;
              width: 112px;
            }

            &:after {
              height: 154px;
              width: 154px;
            }

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-217%, 31%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 7px solid $beige-color;
            }

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-174%, 11%, 0);
              opacity: 0.4;
              border-radius: 50%;
              border: 7px solid $beige-color;
            }
          }
        }
      }
    }

    &__circles-wrapper {
      &_first {
        bottom: 200px;
        left: -220px;
      }

      &_second {
        right: -210px;
        top: -80px;
      }
    }

    &__form {
      margin: 93px 0;

      .recall-form {
        &__title {
          font-size: 25px;
        }

        &__data-container {
          margin-top: 24px;
        }

        &__subtitle {
          margin-top: 19px;
        }

        &__data {
          margin: 0;
        }
      }
    }
  }
}