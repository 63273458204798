.offer {
  height: calc(100vh - 118px);
  background-color: $primary-color;
  position: relative;
  z-index: 2000;
  overflow: hidden;

  &__description-text{
    color: $black-color;
  }

  &__quotes-item {
    display: inline-flex !important;
  }

  &__circles-wrapper {
    position: absolute;
  }

  &__circles {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      opacity: 0.6;
      border-radius: 50%;
      border: 30px solid $beige-color;
    }
  }

  &__quote {
    display: flex;
  }

  &__text {
    text-transform: uppercase;
  }

  &__wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
  }

  &__cont {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
  }

  &__image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-position-y: bottom;
    position: absolute;
    z-index: 15;
    opacity: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    opacity: 0;
    position: relative;
    bottom: -30px;
  }

  &__description-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    position: relative;
    bottom: -15px;
    opacity: 0;
  }

  &__description-commas {
    margin-right: 32px;
  }
}

@include respond-up('x-large') {
  .loaded {
    &.offer {
      .offer {
        &__image {
          left: 1vw;
          opacity: 1;
          transition: 1.2s all ease-in-out;
        }

        &__description-container {
          opacity: 1;
          transition: .9s all .4s ease-in-out;
          bottom: 0;
        }

        &__title {
          opacity: 1;
          transition: .9s all .4s ease-in-out;
          bottom: 0;
        }
      }
    }
  }
}

@include respond-up('x-large') {
  .offer {
    &__quote {
      margin-top: 60px;
    }

    &__cont {
      bottom: -189px;
    }

    &__image {
      height: 65vh;
      width: 70vw;
      bottom: -10px;
      left: -1vw;
    }

    &__circles-wrapper {
      left: 25vw;
      bottom: 0;
    }

    &__description-container {
      margin-right: 5%;
    }

    &__description-commas {
      &_small {
        display: none;
      }
    }

    &__description-text {
      max-width: 600px;
    }
  }
}

@include respond-up('large') {
  .offer {

    &__circles {
      &:before {
        height: 621px;
        width: 621px;
      }

      &:after {
        height: 829px;
        width: 829px;
      }
    }

    &__description-container {
      width: var(--grid-column2);
    }

    &__title {
      font-size: 10vw;
    }

    &__container {
      height: 55vh;
    }
  }
}

@include respond('large') {
  .loaded {
    &.offer {
      .offer {
        &__title {
          opacity: 1;
          transition: 1.3s all .4s ease-in-out;
          bottom: 0;
        }

        &__image {
          left: 86px;
          opacity: 1;
          transition: 1.2s all ease-in-out;
        }

        &__description-container {
          opacity: 1;
          transition: 1.3s all .5s ease-in-out;
          bottom: 0;
        }
      }
    }
  }
}

@include respond('large') {
  .offer {
    &__quote {
      margin-top: 40px;
    }

    &__cont {
      bottom: -127px;
    }

    &__circles-wrapper {
      left: 25vw;
      bottom: 0;
    }

    &__image {
      height: 63vh;
      width: 67vw;
      left: 46px;
      bottom: 0;
    }

    &__description-container {
      margin-right: 5%;
      margin-top: -10px;
    }

    &__description-commas {
      &_small {
        display: none;
      }
    }

    &__description-text {
      max-width: calc(var(--grid-column2) - var(--grid-gap));
    }
  }
}

@include respond-up('medium') {
  .offer {
    &__description-container {
      align-self: flex-end;
    }
  }
}

@include respond('medium') {
  .offer {
    height: calc(100vh - 60px);

    &__circles-wrapper {
      left: 39vw;
      bottom: 4vh;
    }

    &__circles {
      &:before {
        height: 542px;
        width: 542px;
      }

      &:after {
        height: 723px;
        width: 723px;
      }
    }

    &__cont {
      bottom: -84px;
    }

    &__image {
      height: 45vh;
      width: 100vw;
      left: -10vw;
      bottom: 0;
    }

    &__title {
      font-size: 11vw;
      margin-top: 40px;
      text-align: left;
    }

    &__title,
    &__description-container {
      padding-left: 29px;
    }

    &__description-container {
      margin-right: 7%;
      margin-top: 7px;
      width: var(--grid-column6);
    }

    &__description-text {
      max-width: calc(var(--grid-column6) - var(--grid-gap));
    }

    &__description-commas {
      &_small {
        margin-right: 18px;
      }

      &_big {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .loaded {
    &.offer {
      .offer {
        &__title {
          opacity: 1;
          transition: 1.3s all .4s ease-in-out;
          bottom: 0;
        }

        &__image {
          left: -10vw;
          opacity: 1;
          transition: 1.2s all ease-in-out;
        }

        &__description-container {
          opacity: 1;
          transition: 1.3s all .5s ease-in-out;
          bottom: 0;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .offer {
    &__quote {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .offer {
    height: calc(100vh - 60px);

    &__circles {
      &:before {
        height: 344px;
        width: 344px;
      }

      &:after {
        height: 460px;
        width: 460px;
      }
    }

    &__circles-wrapper {
      left: 35vw;
      bottom: 0;
    }

    &__title {
      font-size: 15vw;
      margin-top: 10px;
      text-align: left;
      line-height: 110%;
    }

    &__title,
    &__description-container {
      padding-left: 23px;
    }

    &__cont {
      bottom: -84px;
    }

    &__image {
      height: 45vh;
      width: 140vw;
      left: -14vw;
      bottom: 0;
    }

    &__description-container {
      margin-right: 7%;
      margin-top: 20px;
    }

    &__description-text {
      max-width: calc(var(--grid-column4) - var(--grid-gap));
    }

    &__description-commas {
      &_small {
        margin-right: 18px;
      }

      &_big {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .loaded {
    &.offer {
      .offer {
        &__title {
          opacity: 1;
          transition: 1.3s all .4s ease-in-out;
          bottom: 0;
        }

        &__image {
          left: -10vw;
          opacity: 1;
          transition: 1.2s all ease-in-out;
        }

        &__description-container {
          opacity: 1;
          transition: 1.3s all .5s ease-in-out;
          bottom: 0;
        }
      }
    }
  }
}