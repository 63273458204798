.about {
  position: relative;
  overflow-x: hidden;

  &__advantages-item-title{
    color: $black-color;
  }

  &__title {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    color: $black-color;
  }

  &__advantages-item-number {
    color: $pink-numbers-color;
  }

  &__gallery {
    position: relative;
  }

  &__advantages-item-title-mobile {
    display: none;
  }

  &__gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
}

@include respond-up('x-large') {
  .about {
    &__advantages-item-title {
      width: 340px;
    }

    &__wrapper {
      padding: 0 190px;
    }
  }
}

@include respond-up('large') {
  .about {
    padding-top: 140px;
    padding-bottom: 160px;

    &__gallery {
      margin-top: 160px;
    }

    .container {
      margin-top: 90px;
    }

    &__advantages-item {
      display: flex;
      grid-column: span 2;
    }

    &__advantages-item-number {
      flex: 0 0 76px;
      font-size: 90px;
      transform: translateY(-12px);
    }

    &__advantages-item-title {
      display: block;
      margin-top: 16px;
      margin-bottom: 30px;
    }

    &__gallery:hover {
      .about__arrow-left {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(50px, -50%, 0);
      }

      .about__arrow-right {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(-50px, -50%, 0) rotate(180deg);
      }
    }

    &__gallery-item {
      height: 41.77vw;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__gallery-mobile {
      display: none;
    }

    .slick-slide {
      padding: 0 22px;
    }

    &__arrow-left {
      position: absolute;
      top: 50%;
      left: 247px;
      transform: translateY(-50%);
      opacity: 0;
      pointer-events: none;
      transition: all .5s ease-in-out;
    }

    &__arrow-right {
      position: absolute;
      top: 50%;
      right: 247px;
      transform-origin: 50% 50%;
      transform: translateY(-50%) rotate(180deg);
      opacity: 0;
      pointer-events: none;
      transition: all .5s ease-in-out;
    }

    &__image {
      top: 14.25vw;
    }
  }
}

@include respond('large') {
  .about {
    padding-top: 135px;
    padding-bottom: 134px;

    &__wrapper {
      padding: 0 125px;
    }

    &__advantages-item {
      width: 379px;
    }

    &__advantages-item-title {
      display: block;
      margin-top: 16px;
      margin-bottom: 30px;
    }

    .slick-slide {
      padding: 0 22px;
    }

    &__gallery-item {
      display: block;
      height: 41.77vw;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__gallery-mobile {
      display: none;
    }

    &__image {
      top: 17.25vw;
    }

    &__arrow-left {
      left: 120px;
    }

    &__arrow-right {
      right: 120px;
    }
  }
}

@include respond('medium') {
  .about {
    padding-top: 90px;
    padding-bottom: 80px;

    &__wrapper {
      padding: 0 29px;
    }

    &__advantages-item {
      display: flex;
      width: 344px;
      grid-column: span 6;
    }

    &__advantages-item-number {
      font-size: 70px;
      flex: 0 0 61px;
    }

    &__advantages-item-title {
      display: block;
      margin-top: 16px;
      margin-bottom: 25px;
    }

    .container {
      margin-top: 50px;
    }

    &__gallery-mobile {
      margin-top: 80px;
    }

    &__gallery-mobile-item {
      height: 57.5vw;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__arrows {
      padding-left: 30px;
    }

    .arrow-left {
      margin: 0;
    }

    .slick-slide {
      padding: 0 11px;
      max-width: 588px;
    }

    &__arrow-left {
      display: none;
    }

    &__arrow-right {
      display: none;
    }
  }
}

@include respond-down('small') {
  .about {
    padding-top: 50px;
    padding-bottom: 40px;

    &__gallery-mobile {
      margin-top: 51px;
    }

    &__advantages {
      margin-top: 20px;
    }

    &__advantages-item {
      display: flex;
      width: 100%;
      grid-column: span 4;
    }

    &__wrapper {
      padding: 0 23px;
    }

    &__advantages-item-number {
      display: block;
      font-size: 60px;
      flex: 0 0 47px;
    }

    &__advantages-item-title {
      display: block;
      padding-top: 10px;
      width: 194px;
      margin-bottom: 20px;
    }

    &__advantages-item-text {
      line-height: 1.44em;
    }

    .slick-slide {
      padding: 0;
    }

    &__gallery-mobile-item {
      height: 73.77vw;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__gallery {
      display: none;
    }

    &__arrows {
      padding-left: 22px;
    }
  }
}
