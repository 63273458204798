.error-page {
  background-color: $primary-color;

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__small-text,
  &__text {
    text-align: center;
  }

  &__404 {
    svg {
      width: 100%;
    }
  }
}

@include respond-up('large') {
  .error-page {
    &__main {
      padding-top: 60px;
      padding-bottom: 155px;
    }

    &__text {
      margin-top: 80px;
    }

    &__small-text {
      margin-top: 20px;
    }

    &__buttons {
      margin-top: 35px;
    }

    &__404 {
      width: calc(var(--grid-column2) + var(--grid-gap) + var(--grid-column1) / 2);
    }
  }
}

@include respond('medium') {
  .error-page {
    &__main {
      padding-top: 60px;
      padding-bottom: 190px;
    }

    &__text {
      margin-top: 80px;
    }

    &__small-text {
      margin-top: 16px;
    }

    &__buttons {
      margin-top: 35px;
    }

    &__404 {
      width: var(--grid-column10);
    }
  }
}

@include respond-down('small') {
  .error-page {
    &__main {
      padding-top: 45px;
      padding-bottom: 261px;
    }

    &__text {
      margin-top: 60px;
    }

    &__small-text {
      margin-top: 12px;
    }

    &__buttons {
      margin-top: 28px;
    }

    &__404 {
      width: calc(var(--grid-column4) - var(--grid-gap));
    }
  }
}