.button {
  color: #fff;
  background-color: $pink-color;
  font-family: $second-font;
  text-align: center;
  padding: 0 54px;
  height: 49px;
  border-radius: 100px;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: fit-content;

  &_low {
    height: 46px;
  }
}

a.button {
  display: flex;
  align-items: center;
}

@include respond-up('large') {
  .button {
    &:hover {
      background: $button-bg-hover;
    }
  }
}