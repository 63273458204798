.footer {

  &__nav-link {
    font-size: 15px;
    color: $black-color;
    text-decoration: none;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.02em;
    display: block;

    &:hover {
      .footer {
        &__nav-name {
          &:before {
            width: 100%;
            left: 0;
            transition: all .55s cubic-bezier(.91, .4, .15, 1);
          }
        }
      }
    }
  }

  &__nav-name {
    position: relative;

    &:before {
      content: '';
      width: 0;
      height: 1px;
      position: absolute;
      bottom: -4px;
      right: 0;
      background-color: $black-color;
      transition: all .34s cubic-bezier(.72, .2, .15, 1);
    }
  }

  &__line {
    height: 1px;
    background-color: $light-gray-color;
    width: 100%;
  }

  &__phone {
    font-family: $second-font;
    letter-spacing: 1px;
    text-decoration: none;
    color: $black-color;
    font-weight: 600;
  }

  &__phone-small {
    display: block;
    font-family: $second-font;
    text-decoration: none;
    color: $black-color;
    font-weight: 600;
    letter-spacing: -0.02em;
  }

  &__license-label {
    display: block;
    color: $gray-color;
    line-height: 145%;
    font-size: 14px;
    font-weight: 400;

    &:nth-child(2) {
      margin-top: 25px;
    }
  }

  &__info-item {
    color: $gray-color;
  }

  &__info-link {
    text-decoration: none;
    color: $gray-color;
    font-size: 14px;
    transition: $default-transition;
    font-weight: 400;

    &_green {
      color: $green-color;
    }

    &:hover {
      color: $black-color;
    }
  }
}

@include respond-up('x-large') {
  .footer {
    padding-top: 102px;
    padding-bottom: 71px;

    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
    }

    &__nav {
      display: flex;
      margin-left: -15px;
    }

    &__nav-link {
      padding: 5px 15px;
    }

    &__line {
      margin-bottom: 50px;
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
    }

    &__info-list {
      display: flex;
      margin-right: -35px;
    }

    &__info-item {
      padding: 0 35px;

      &:nth-child(2) {
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        letter-spacing: -0.02em;
      }
    }

    &__phone-small {
      display: none;
    }
  }
}

@include respond('large') {
  .footer {
    padding-top: 82px;
    padding-bottom: 64px;

    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
    }

    &__nav {
      display: flex;
      margin-left: -15px;
    }

    &__nav-link {
      padding: 5px 15px;
    }

    &__phone-small {
      display: none;
    }

    &__line {
      margin-bottom: 50px;
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
    }

    &__license {
      width: 537px;
    }

    &__info-list {
      display: flex;
      margin-right: -35px;
    }

    &__info-item {
      padding: 0 35px;

      &:nth-child(2) {
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        letter-spacing: -0.02em;
      }
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__license {
      width: 710px;
      letter-spacing: -0.02em;
    }
  }
}

@include respond('medium') {
  .footer {
    padding: 50px 0;

    &__phone {
      display: none;
    }

    &__top {
      display: flex;
      flex-direction: column;
    }

    &__nav {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      margin-left: -15px;
    }

    &__phone-small {
      font-size: 18px;
      line-height: 22px;
    }

    &__nav-link {
      padding: 15px;
    }

    &__line {
      margin-top: 31px;
    }

    &__license {
      margin-top: 30px;
    }

    &__info {
      margin-top: 40px;
    }

    &__info-list {
      display: flex;
      flex-direction: row;
    }

    &__info-item {
      &:first-child {
        margin-right: 75px;
      }

      &:nth-child(2) {
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        letter-spacing: -0.02em;
      }
    }
  }
}

@include respond-down('small') {
  .footer {
    padding-top: 40px;
    padding-bottom: 29px;

    &__phone {
      display: none;
    }

    &__phone-small {
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 20px;
    }

    &__nav {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 114px;
      margin-bottom: 40px;
    }

    &__nav-item {
      font-weight: 500;
      font-size: 15px;
      line-height: 120%;
      letter-spacing: -0.02em;

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        padding-left: 25%;
      }
    }

    &__nav-link {
      padding: 10px 0;
    }

    &__line {
      display: none;
    }

    &__line-mobile {
      width: 100%;
      height: 1px;
      background-color: $light-gray-color;
      margin: 23px 0;
    }

    &__license-label {
      display: block;
      font-size: 13px;
      letter-spacing: -0.02em;

      &:nth-child(2) {
        margin-top: 12px;
      }
    }

    &__info-item {
      &:nth-child(2) {
        margin-top: 16px;
        font-weight: 400;
        font-size: 13px;
        line-height: 145%;
        letter-spacing: -0.02em;
      }
    }

    &__info-link {
      font-size: 13px;
    }
  }
}

