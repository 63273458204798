.documents {
  &__link-icon {
    height: 48px;
    width: 48px;
    display: flex;
  }

  &__main {
    display: flex;
    flex-direction: column;
  }

  &__title {
    text-align: center;
    color: $black-color;
  }

  &__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
}

@include respond-up("x-large") {
  .documents {
  }
}

@include respond("large") {
  .documents {
  }
}

@include respond-up("large") {
  .documents {
    max-width: 1000px;
    margin: 0 auto;

    &__main {
      margin-top: 66px;
    }

    &__title {
      margin-top: 70px;
    }
  }
}

@include respond-up("medium") {
  .documents {
    &__link-icon {
      margin-right: 20px;
    }

    &__link {
      &:not(:first-child) {
        margin-top: 25px;
      }
    }
  }
}

@include respond("medium") {
  .documents {
    &__page {
      padding-top: 70px;
      padding-bottom: 90px;
      min-height: 60vh;
    }

    &__main {
      margin-top: 35px;
    }
  }
}

@include respond-down("medium") {
  .documents {
  }
}

@include respond-down("small") {
  .documents {
    &__page {
      padding-top: 30px;
      padding-bottom: 50px;
      min-height: 40vh;
    }

    &__link-icon {
      margin-right: 15px;
    }

    &__main {
      margin-top: 29px;
    }

    &__link {
      &:not(:first-child) {
        margin-top: 15px;
      }
    }
  }
}
