.uikit-page {
  padding-bottom: 100px;

  &__element_warning {
    margin-top: 50px;
    padding: 10px 10px 10px 50px;
    background-color: $primary-color;
    color: $dark-blue-color;
    font-weight: 500;
    flex: 0 0 85%;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 34px 0 34px 34px;
      border-color: transparent transparent transparent white;
    }
  }

  &__elements-list {
    margin-top: 75px;
  }

  &__element {
    margin-top: 40px;

    &_wide {
      flex: 0 0 100% !important;
    }

    &_dark {
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      background-color: $dark-blue-color;

      .uikit-page__element-desc {
        margin-right: -10px;
        margin-left: -10px;
      }
    }
  }

  &__element-desc {
    margin-top: 20px;
    padding: 20px;
    background: $dark-blue-color;
    color: white;
  }

  &__elements-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@include respond-up('large') {
  .uikit-page {
    &__element {
      flex: 0 0 48%;
    }
  }
}

@include respond('medium') {
  .uikit-page {

  }
}

@include respond-down('medium') {
  .uikit-page {
    &__element {
      flex: 0 0 100%;
    }
  }
}

@include respond-down('small') {
  .uikit-page {

  }
}
