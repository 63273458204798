h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  font-family: $h-font;
  color: $black-color;
  font-style: normal;
}

h1,
.h1, {
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

h2,
.h2, {
  font-weight: 500;
  line-height: 135%;
  letter-spacing: -0.02em;
}
h3,
.h3, {
  font-weight: 500;
  line-height: 125%;
  letter-spacing: -0.02em;
}
h4,
.h4, {
  font-weight: 500;
  line-height: 135%;
  letter-spacing: -0.02em;
}
h5,
.h5, {
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.02em;
}

@include respond-up('x-large') {
  $h1-size: 120px;
  $h2-size: 45px;
  $h3-size: 35px;
  $h4-size: 22px;
  $h5-size: 18px;

  h1,
  .h1 {
    font-size: $h1-size;
  }

  h2,
  .h2 {
    font-size: $h2-size;
  }

  h3,
  .h3 {
    font-size: $h3-size;
  }

  h4,
  .h4 {
    font-size: $h4-size;
  }

  h5,
  .h5 {
    font-size: $h5-size;
  }
}

@include respond('large') {
  $h1-size: 100px;
  $h2-size: 38px;
  $h3-size: 35px;
  $h4-size: 22px;
  $h5-size: 18px;

  h1,
  .h1 {
    font-size: $h1-size;
  }

  h2,
  .h2 {
    font-size: $h2-size;
  }

  h3,
  .h3 {
    font-size: $h3-size;
  }

  h4,
  .h4 {
    font-size: $h4-size;
  }

  h5,
  .h5 {
    font-size: $h5-size;
  }
}

@include respond('medium') {
  $h1-size: 60px;
  $h2-size: 32px;
  $h3-size: 26px;
  $h4-size: 22px;
  $h5-size: 18px;

  h1,
  .h1 {
    font-size: $h1-size;
  }

  h2,
  .h2 {
    font-size: $h2-size;
  }

  h3,
  .h3 {
    font-size: $h3-size;
  }

  h4,
  .h4 {
    font-size: $h4-size;
  }

  h5,
  .h5 {
    font-size: $h5-size;
  }
}

@include respond-down('small') {
  $h1-size: 38px;
  $h2-size: 25px;
  $h3-size: 22px;
  $h4-size: 18px;
  $h5-size: 16px;

  h1,
  .h1 {
    font-size: $h1-size;
  }

  h2,
  .h2 {
    font-size: $h2-size;
  }

  h3,
  .h3 {
    font-size: $h3-size;
  }

  h4,
  .h4 {
    font-size: $h4-size;
  }

  h5,
  .h5 {
    font-size: $h5-size;
  }
}