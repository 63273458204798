$default-input-height: 46px;

.button, button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: $default-transition;
  display: inline-block;
  border: none;
  background: $primary-color;
  font-size: 16px;
  cursor: pointer;

  &.expand, &.wide {
    width: 100%;
  }

  &.height {
    height: $default-input-height;
  }
}

form {
  label {
    font-size: 12px;
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: $alert-color;
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';
  #{$inputs}, textarea, select {
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: 1px solid $black-color;
    background-color: transparent;
    font-family: $second-font;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 165%;
    letter-spacing: -0.02em;
    padding: 18px 0;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 50px white inset !important;
    }

    &::placeholder {
      color: $black-color;
      transition: transform .25s ease-in-out;
      transform-origin: 0 100%;
    }

    &:focus::placeholder {
      transform: translateY(-22px) scale(.7);
    }

    border-radius: 0;
    box-shadow: none;
    margin: 0;
    width: 100%;
    display: block;
  }

  select {
    height: $default-input-height;
  }
}