.stocks {
  background-color: $primary-color;
  position: relative;

  &__arrows {
    .arrow-left {
      margin-right: 0;
    }
  }

  &__info-button {
    margin-top: 40px;
    font-weight: 600;
    font-size: 13px;
  }

  &__info-lable {
    margin-top: 8px;
  }

  &__images-item {
    height: 100%;
    display: block
  }

  &__image-item {
    display: block;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__arrows {
    background-color: $primary-color;
    z-index: 1000;
  }

  &__title {
    &_white {
      color: white;
    }
  }

  &__info-list {
    background-color: white;
  }

  &__info {
    width: 100%;
    height: 100%;
  }

  &__info-text {
    flex-grow: 1;
  }
}

@include respond-up('x-large') {
  .stocks {
    &__arrows {
      .arrow-left {
        margin-right: 20px;
      }
    }

    &__sliders {
      position: relative;
      display: flex;
      align-items: stretch;
    }

    &__info-list {
      width: 831px;
    }

    &__info-item {
      //height: 563px;
      align-items: center;
      padding: 80px 0 86px 115px;
    }

    &__info-wrapper {
      height: 100%;
      width: 482px;
    }

    &__images-list {
      position: absolute;
      right: 0;
      top: 50px;
      height: 100%;
      width: 827px;

      & .slick-list {
        height: 100%;
      }

      & .slick-track {
        height: 100%;
      }

      & div {
        height: 100%;
      }
    }

    &__image-item {
      display: block;
      max-height: 563px;
    }
  }
}

@include respond-up('large') {
  .stocks {
    padding-top: 120px;
    padding-bottom: 208px;

    &__info-label {
      margin-top: 7px;
    }

    &__arrows {
      margin-top: 25px;
    }

    &__mobile-list {
      display: none;
    }

    &__title {
      &_white {
        margin-bottom: 90px;
      }
    }

    &__info-price {
      display: block;
      font-weight: 400;
      font-size: 90px;
      line-height: 1.20em;
    }

    &__info-label {
      display: block;
      font-weight: 500;
      font-size: 22px;
      line-height: 1.35em;
    }

    &__info-text {
      display: block;
      margin-top: 30px;
    }

    &__info-button {
      margin-top: 40px;
      padding: 0 38px;
      height: 52px;
    }
  }
}

@include respond('large') {
  .stocks {
    padding-bottom: 174px;

    &__sliders {
      position: relative;
      display: flex;
      align-items: stretch;
    }

    &__info-list {
      width: 612px;
    }

    &__info-item {
      align-items: center;
      padding: 55px 0 57px 60px;
    }

    &__info-wrapper {
      height: 100%;
      width: 385px;
    }

    &__images-list {
      position: absolute;
      right: 0;
      top: 50px;
      height: 100%;
      width: 745px;

      & .slick-list {
        height: 100%;
      }

      & .slick-track {
        height: 100%;
      }

      & div {
        height: 100%;
      }
    }

    &__image-item {
      display: block;
    }
  }
}

@include respond-down('medium') {
  .stocks {
    &__arrows {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .stocks {
    padding: 70px 0;

    &__info-wrapper {
      width: var(--grid-column4);
    }

    &__sliders {
      position: relative;
      display: flex;
      align-items: stretch;
    }

    &__info-list {
      width: var(--grid-column9);
      min-height: calc(var(--grid-column9) * 385 / 527);
    }

    &__info-item {
      align-items: center;
      padding: 35px 0 37px 35px;
    }

    &__info-wrapper {
      height: 100%;
    }

    &__images-list {
      position: absolute;
      bottom: -36px;
      right: 0;
      height: calc((var(--grid-column6) + var(--grid-gap)) * 258 / 366);
      width: calc(var(--grid-column6) + var(--grid-gap));

      & .slick-list {
        height: 100%;
      }

      & .slick-track {
        height: 100%;
      }

      & div {
        height: 100%;
      }
    }

    &__image-item {
      display: block;
    }

    &__mobile-list {
      display: none;
    }

    &__title {
      &_white {
        margin-bottom: 35px;
      }
    }

    &__info-price {
      display: block;
      font-weight: 400;
      font-size: 50px;
      line-height: 1.20em;
    }

    &__info-label {
      margin-top: 7px;
      display: block;
      font-weight: 500;
      font-size: 22px;
      line-height: 1.35em;
    }

    &__info-text {
      display: block;
      margin-top: 17px;
    }

    &__info-button {
      margin-top: 25px;
      padding: 0 38px;
      height: 48px;
    }
  }
}

@include respond-down('small') {
  .stocks {
    &__mobile-info-button {
      margin-top: 23px;
      padding: 0 38px;
      height: 48px;
    }

    padding: 40px 0;

    &__title {
      &_white {
        margin-bottom: 28px;
      }
    }

    &__images-list {
      display: none;
    }

    &__image-item {
      width: 100%;
      height: 217px;
    }

    &__mobile-item {
      width: 100%;
      position: relative;
      padding: 0 1px;
    }

    &__mobile-info {
      min-height: 358px;
    }

    &__mobile-item-wrapper {
      position: relative;
      display: block;
      left: 50%;
      transform: translateX(-50%);
    }

    &__mobile-image {
      width: 100%;
      height: 217px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__mobile-info {
      padding: 25px 18px 35px;
      background-color: white;
    }

    &__info-list {
      display: none;
    }

    &__mobile-info-price {
      display: block;
      font-weight: 400;
      font-size: 50px;
      line-height: 1.20em;
    }

    &__mobile-info-label {
      display: block;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.35em;
      margin-top: 8px;
    }

    &__mobile-info-text {
      display: block;
      margin-top: 18px;
    }
  }
}