.text {
  font-style: normal;
  letter-spacing: -0.02em;
  font-family: $font;
  $main-size: 15px;

  &__phone {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: $black-color;
  }

  &__offer {
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.02em;
  }

  &_black {
    color: $black-color;
  }

  &__services {
    font-weight: 500;
    line-height: 1.35em;
    color: $black-color;
  }

  &__document {
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.02em;
  }

  &__list {
    font-weight: 400;
    line-height: 1.35em;
    color: $grey-main-text-color;
  }

  &__main {
    font-size: $main-size;
    font-weight: 400;
    line-height: 1.65em;
    color: $grey-main-text-color;
  }
}

@include respond-up('x-large') {
  .text {
    &__offer {
      font-size: 26px;
    }
  }
}

@include respond-up('large') {
  .text {
    &__services {
      font-size: 19px;
    }
  }
}

@include respond('large') {
  .text {
    &__offer {
      font-size: 22px; //large
    }
  }
}

@include respond-up('medium') {
  .text {
    &__document,
    &__list {
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .text {
    &__services {
      font-size: 18px;
    }
  }
}

@include respond-down('medium') {
  .text {
    &__offer {
      font-size: 16px; //medium down
    }
  }
}

@include respond-down('small') {
  .text {
    &__document,
    &__services,
    &__list {
      font-size: 15px;
    }
  }
}